import { AutomatitCarousel } from './automatit_carousel.m';

const reviewsCaro = {
	caro: document.querySelector('#inc_reviews_caro'),
	init: function() {
		window.reviewsCaro = AutomatitCarousel({
			element: this.caro,
			useChevrons: false
		});
	}
};

export const initReviewsCaro = () => reviewsCaro.init();