import './polyfills.js';

import { initMobileNav } from './header.js';
import { initHomeCaro } from './home.js';
import { initReviewsCaro } from './reviews.js';
import { initHomeMap } from './multiMap.js';
import { initFacilityTop, initFacilityFilters } from './facility.js';
import { contactSubmit } from './contact.js';
import { initTips } from './tips.js';

initMobileNav();

if(document.querySelector('#home_caro')) {
	initHomeCaro();
}

if(document.querySelector('#inc_reviews_caro')) {
	initReviewsCaro();
}

if(document.querySelector('#inc_map')) {
	initHomeMap();
}

if(document.querySelector('#facility_top')) {
	initFacilityTop();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message']
	});
}
if (document.getElementById('tips')) {
	initTips();
}