if(document.getElementById('tip_list_wrap')) {
	setTimeout(function(){
		document.getElementsByClassName('tip_item_wrap')[0].classList.add('active');
	}, 500);
}

const tipsButtons = {
	btnClick: function(e){
		if(e.target && e.target.nodeName === "BUTTON") {
			let el = e.target.parentElement;
			if(el.classList.contains('active')) {
				el.classList.toggle('active');
			}
			else {
				el.classList.add('active');
			}
		}
	},
	init: function() {
		document.getElementById('tip_list_wrap').addEventListener('click', e => this.btnClick(e));
	}
};

export const initTips = () => tipsButtons.init();