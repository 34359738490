/* global google */
import { AutomatitCarousel } from './automatit_carousel.m';
import { getMapsHelper } from './util/mapsHelper';

const facilityTop = {
	mobileToggle: document.querySelector('#toggle_fac_info'),
	info: document.querySelector('#facility_top'),
	caro: document.querySelector('#facility_caro'),
	mapButton: document.querySelector('#facility_map_caro_controls .map'),
	theMap: {
		ele: document.querySelector('#facility_map'),
		instance: null
	},
	mapToggle: function() {
		this.theMap.ele.toggleAttribute('active');
	},
	mobileClick: function() {
		this.mobileToggle.switchAttribute('state', 'closed', 'open');
		this.info.switchAttribute('state', 'closed', 'open');
	},
	init: async function() {
		this.mobileToggle.addEventListener('click', () => this.mobileClick());
		if(window.facCaroImgs.length) { 
			window.facCaro = AutomatitCarousel({
				element: this.caro,
				images: window.facCaroImgs,
				imagesAsBackgrounds: true,
				useChevrons: false
			});
		} else {
			// placeholder functions to prevent console errors if there aren't any caro images
			window.facCaro = {};
			window.facCaro.slideLeft = () => {
				//
			};
			window.facCaro.slideRight = () => {
				//
			};
			this.caro.style.backgroundImage = `url('/dist/images/content/rates_placeholder.jpg')`;
		}

		
		const mapsHelper = getMapsHelper();

		await mapsHelper.ready();

		this.theMap.instance = mapsHelper.createMap({
			element: this.theMap.ele,
			locationElementSelector: '#facility_map',
			useRichmarker: true,
			markerReducer: el => {
				return {
					lat: el.getAttribute('lat'),
					lng: el.getAttribute('lng'),
					content: `<span><img src="/dist/images/icons/map_pin.svg" alt="" /></span>`
				};
			}
		});

		google.maps.event.addListenerOnce(this.theMap.instance, 'idle', () => {
			this.theMap.instance.setZoom(14);
			this.mapButton.addEventListener('click', () => this.mapToggle());
		});
	}
};

const facilityFilters = {
	filters: document.querySelectorAll('#facility_filters button'),
	filterWrap: document.querySelector('#facility_filters'),
	rows: document.querySelectorAll('.rates_row'),
	filterClick: function(e) {
		const clickedFilter = e.target.closest('button');

		if(clickedFilter) {
			this.filters.forEach(filter => filter.removeAttribute('active'));
			clickedFilter.setAttribute('active', true);

			const activeFilter = document.querySelector('#facility_filters button[active]');

			this.rows.forEach(row => {
				row.setAttribute('hide', true);

				if(row.getAttribute('size') === activeFilter.getAttribute('size') || activeFilter.getAttribute('size') === 'all') {
					row.removeAttribute('hide');
				}
			});
		}
	},
	init: function() {
		this.filterWrap.addEventListener('click', e => this.filterClick(e));
	}
};

export const initFacilityTop = () => facilityTop.init();
export const initFacilityFilters = () => facilityFilters.init();