import { AutomatitCarousel } from './automatit_carousel.m';

const homeCaro = {
	caro: document.querySelector('#home_caro'),
	init: function() {
		AutomatitCarousel({
			element: this.caro,
			images: [
				{src: "/dist/images/content/home_slider1.jpg", alt: ""},
				{src: "/dist/images/content/home_slider2.jpg", alt: ""},
				{src: "/dist/images/content/home_slider3.jpg", alt: ""},
				{src: "/dist/images/content/home_slider4.jpg", alt: ""},
			],
			imagesAsBackgrounds: true,
			useChevrons: false,
			showDots: true
		});
	}
};

export const initHomeCaro = () => homeCaro.init();